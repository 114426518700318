import CustomModalBody from "components/Modal/body";
import CustomModalFooter from "components/Modal/footer";
import { useState } from "react";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";

export default function FilterForm({ onSubmit, onChangeHOC, handleState, specializationList }) {
	const [invoiceStartDate, setInvoiceStartDate] = useState(null);
	const [invoiceEndDate, setInvoiceEndDate] = useState(null);
	const [advertiserId, setAdvertiserId] = useState("");
	const [advertiser, setAdvertiser] = useState("");
	const [invoiceNumber, setInvoiceNumber] = useState("");
	// const [category, setCategory] = useState("");
	const [agentName, setAgentName] = useState("");
	const [agentID, setAgentID] = useState("");
	const [agentLeadID, setAgentLeadID] = useState("");
	const [orderNo, setOrderNo] = useState("");
	const [specialization, setSpecialization] = useState("");
	const [agentLeadName, setAgentLeadName] = useState("");

	const handleClear = () => {
		setInvoiceStartDate("");
		setInvoiceEndDate("");
		setAdvertiserId("");
		setAdvertiser("");
		setInvoiceNumber("");
		// setCategory("");
		setAgentName("");
		setAgentID("");
		setAgentLeadID("");
		setOrderNo("");
		setSpecialization("");
		setAgentLeadName("");
	};

	const generateQuery = () => {
		let queryString = "";

		if (invoiceStartDate){ queryString += `&invoice_start_date=${invoiceStartDate}`}
		if (invoiceEndDate){ queryString += `&invoice_end_date=${invoiceEndDate}`}
		if (invoiceNumber){ queryString += `&invoice_no=${invoiceNumber}`}
		if (orderNo){ queryString += `&order_number=${orderNo}`}
		if (specialization){ queryString += `&specialization_id=${specialization}`}
		// if (category){ queryString += `&category_id=${category}`} //havent support yet
		if (agentName){ queryString += `&agent_name=${agentName}`}
		if (agentID) { queryString += `&agent_id=${agentID}` }
		if (agentLeadID){ queryString += `&agent_lead_id=${agentLeadID}`}
		if (agentLeadName){ queryString += `&agent_lead_name=${agentLeadName}`}
		if (advertiserId){ queryString += `&advertiser_id=${advertiserId}`}
		if (advertiser){ queryString += `&company_name=${advertiser}`}

		return queryString;
	};

	return (
		<>
			<CustomModalBody>
				<Form id="billing-filter-form">
					<Row>
						<Col lg={3}>
							<FormGroup>
								<Label for="invoiceStartDate">Start Date </Label>
								<Input
									type="date"
									placeholder="Enter Start Date"
									name="invoiceStartDate"
									id="invoiceStartDate"
									value={invoiceStartDate}
									onChange={(e) => {setInvoiceStartDate(e.target.value)}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="invoiceEndDate">End Date </Label>
								<Input
									type="date"
									placeholder="Enter "
									name="invoiceEndDate"
									id="invoiceEndDate"
									value={invoiceEndDate}
									onChange={(e) => {setInvoiceEndDate(e.target.value)}}
								/>
							</FormGroup>
						</Col>
						{/* <Col lg={3}>
							<FormGroup>
								<Label for="advertiserId">Advertiser ID </Label>
								<Input
									placeholder="Enter Advertiser ID"
									name="advertiserId"
									id="advertiserId"
									value={advertiserId}
									onChange={(e) => {setAdvertiserId(e.target.value)}}
								/>
							</FormGroup>
						</Col> */}
						<Col lg={3}>
							<FormGroup>
								<Label for="advertiser">Advertiser Name </Label>
								<Input
									placeholder="Enter Advertiser Name"
									name="advertiser"
									id="advertiser"
									value={advertiser}
									onChange={(e) => {setAdvertiser(e.target.value)}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="invoiceNumber">Invoice </Label>
								<Input
									placeholder="Enter Invoice No."
									name="invoiceNumber"
									id="invoiceNumber"
									value={invoiceNumber}
									onChange={(e) => {setInvoiceNumber(e.target.value)}}
								/>
							</FormGroup>
						</Col>
						{/* <Col lg={3}>
							<FormGroup>
								<Label for="category">Category </Label>
								<Input
									placeholder="Enter Category"
									name="category"
									id="category"
									value={category}
									onChange={(e) => {setCategory(e.target.value)}}
								/>
							</FormGroup>
						</Col> */}
						<Col lg={3}>
							<FormGroup>
								<Label for="agentName">Agent Name </Label>
								<Input
									placeholder="Enter Agent Name"
									name="agentName"
									id="agentName"
									value={agentName}
									onChange={(e) => {setAgentName(e.target.value)}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="orderNo">Order No. </Label>
								<Input
									placeholder="Enter Order No."
									name="orderNo"
									id="orderNo"
									value={orderNo}
									onChange={(e) => {setOrderNo(e.target.value)}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="specialization">Specialization </Label>
								<Input
									type="select"
									name="specialization"
									id="specialization"
									value={specialization}
									onChange={(e) => {setSpecialization(e.target.value)}}
								>
									<option value={""}>All</option>
									{specializationList.map((item)=>{
										return <option key={item.id} value={item.id}>
											{item.name.en}
										</option>
									})}
								</Input>
							</FormGroup>
						</Col>
						<Col lg={3}>
                            <FormGroup>
                                <Label for="agentID">Agent ID </Label>
                                <Input
                                    placeholder="Enter Agent ID"
                                    name="agentID"
                                    id="agentID"
                                    value={agentID}
                                    onChange={(e) => { setAgentID(e.target.value) }}
                                />
                            </FormGroup>
                        </Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="agentLeadID">Agent Lead ID</Label>
								<Input
									placeholder="Enter Agent Lead ID"
									name="agentLeadID"
									id="agentLeadID"
									value={agentLeadID}
									onChange={(e) => {setAgentLeadID(e.target.value)}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="agentLeadName">Agent Lead Name </Label>
								<Input
									placeholder="Enter Agent Lead Name"
									name="agentLeadName"
									id="agentLeadName"
									value={agentLeadName}
									onChange={(e) => {setAgentLeadName(e.target.value)}}
								/>
							</FormGroup>
						</Col>
					</Row>
				</Form>
			</CustomModalBody>
			<CustomModalFooter
				rightButton={[
					{
						color: "danger",
						outline: true,
						content: "Clear All",
						onClick: () => handleClear(),
					},
					{
						color: "primary",
						content: "Submit",
						onClick: () => {
							const queryString = generateQuery();
							onSubmit(queryString);
							handleState({ filterQuery: queryString });
							onChangeHOC("showFilterModal", false);
							onChangeHOC("advertiserPages", 1);
						},
					},
				]}
			/>
		</>
	);
}
